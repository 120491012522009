define("discourse/plugins/custom trust level/discourse/initializers/plugin-init", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "discourse/models/user", "I18n", "discourse/lib/utilities"], function (_exports, _pluginApi, _decorators, _user, _I18n, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "enable-links",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.33", pluginInit);
    }
  };
  function pluginInit(api) {
    api.modifyClass("controller:user", dt7948.p({
      linkWebsite(settingEnabled) {
        return settingEnabled || this._super(...arguments);
      }
    }, [["method", "linkWebsite", [(0, _decorators.default)("siteSettings.csl_display_tl0_website_as_link")]]]));
    const currentUser = api.getCurrentUser();
    const siteSettings = api.container.lookup("site-settings:main");
    if (currentUser && !currentUser.allow_trust_level_upgrade) {
      api.addGlobalNotice(`Please read <a href='${(0, _utilities.postUrl)(null, parseInt(siteSettings.csl_topic_id_for_trust_level_freeze))}'>Beginners Guide</a> topic and make sure to give it a like.`, "trust-level-freeze-notice", {
        html: "<h3>Your are freezed on a trust level!</h3>"
      });
    }
    api.modifyClass("component:topic-footer-buttons", dt7948.p({
      subscribe() {
        const currentUser = _user.default.current();
        if (currentUser && !this.topic.isPrivateMessage) {
          this.messageBus.subscribe(`/${currentUser.id}/custom_can_create_post`, canCreate => {
            this.set("topic.details.can_create_post", canCreate);
          });
        }
      }
    }, [["method", "subscribe", [(0, _decorators.on)("init")]]]));
  }
});